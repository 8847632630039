import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from '@mui/styles';

interface QuestionsComponentProps {
  question: string;
  number: string;
  onClick: (value: string) => void;
}
const useStyles = makeStyles(() => {
  return {
    Button: {
      color: '#2D11AD',
      borderColor: '#2D11AD',
      width: '152px',
    },
    Question: {
      color: '#475467',
      fontSize: '18px',
      margin: '30px 0px',
      //   width: '75%',
    },
  };
});
export const QuestionsComponent: FC<QuestionsComponentProps> = ({ question, number, onClick }) => {
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      flexDirection={'column'}
      padding="0px 30px"
    >
      <p className={classes.Question}> {`${number}. ${question}`}</p>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap="8px">
        <Button className={classes.Button} variant="outlined" onClick={() => onClick('YES')}>
          YES
        </Button>
        <Button className={classes.Button} variant="outlined" onClick={() => onClick('NO')}>
          NO
        </Button>
      </Box>
    </Box>
  );
};
